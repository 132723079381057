import React, { FC } from 'react';

import { DealTypes } from '@constants/common';
import { colors } from '@constants/colors';
import { Chip } from '@mui/material';
import { translateDynamic } from '@utils/locale';
import { hexToRgba } from '@utils/common';

import { DealTypeChipProps } from './types';

const DealTypeChip: FC<DealTypeChipProps> = ({ dealType }) => {
  const getColor = (dealTypes: DealTypes) => {
    switch (dealTypes) {
      case DealTypes.PRIVATE:
        return {
          backgroundColor: hexToRgba(colors.lightSkyBlue, 0.2),
          textColor: colors.steelBlue
        };
      case DealTypes.PUBLIC:
        return {
          backgroundColor: hexToRgba(colors.royalBlue, 0.4),
          textColor: colors.royalBlue
        };
      case DealTypes.SYNDICATE:
        return {
          backgroundColor: hexToRgba(colors.deepIndigo, 0.2),
          textColor: colors.deepIndigo
        };
      default:
        return {
          backgroundColor: hexToRgba(colors.gray, 0.2),
          textColor: colors.gray
        };
    }
  };

  const { backgroundColor, textColor } = getColor(dealType);

  return (
    <Chip
      label={translateDynamic(`dealType.${dealType}`)}
      size="medium"
      variant="filled"
      style={{
        color: textColor,
        width: 145,
        backgroundColor
      }}
    />
  );
};

export default DealTypeChip;
