import { createSlice } from '@reduxjs/toolkit';

type regeneratedDocs = {
  aifId: string;
  dealId?: string;
  docName: string;
  tabName: string;
};

const initialState: {
  downloadAifReportState: boolean | 'loading';
  regeneratedDocs: regeneratedDocs[];
} = {
  downloadAifReportState: false,
  regeneratedDocs: []
};

export const globalApiSupportSlice = createSlice({
  name: 'globalApiSupport',
  initialState,
  reducers: {
    updateDownloadAifReportState: (state, { payload }) => {
      state.downloadAifReportState = payload;
    },
    setRegeneratedDocs: (state, { payload }) => {
      state.regeneratedDocs.push(payload);
    },
    clearRegeneratedDocs: state => {
      state.regeneratedDocs = [];
    }
  }
});

export const {
  updateDownloadAifReportState,
  setRegeneratedDocs,
  clearRegeneratedDocs
} = globalApiSupportSlice.actions;

export default globalApiSupportSlice.reducer;
