import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { Step, StepButton, StepLabel, Stepper } from '@mui/material';

import { colors } from '@constants/colors';

import { StepperProps } from './types';

const HorizontalStepper: FC<StepperProps> = ({
  activeStep,
  completed,
  handleStep,
  steps
}) => {
  return (
    <div className="w-full h-full">
      <Box sx={{ width: '100%' }}>
        <Stepper
          nonLinear
          activeStep={activeStep}
          sx={{
            '& .MuiStepLabel-iconContainer .Mui-completed': {
              color: colors.successGreen // circle color (COMPLETED)
            },
            '& .MuiStepLabel-iconContainer .Mui-active': {
              color: colors.primaryBlue, // circle color (ACTIVE)
              width: '1.3em',
              height: '1.3em'
            },
            '& .MuiStepLabel-iconContainer svg:hover': {
              color: colors.gray02 // circle color (HOVER)
            },
            '& .MuiStepLabel-iconContainer .Mui-active:hover': {
              color: colors.primaryBlue // circle color (ACTIVE HOVER)
            },
            '& .MuiStepLabel-labelContainer .Mui-active': {
              color: colors.primaryBlue // label color (LABEL COLOR - ACTIVE STATE)
            },
            '& .MuiStepLabel-labelContainer': {
              color: '#00000060' //label color (DEFAULT)
            },
            '& .MuiStepLabel-labelContainer .Mui-completed': {
              color: '#00000060' //label color (COMPLETED)
            },
            '& .MuiStepLabel-iconContainer': {
              color: '#00000038' // circle color (DEFAULT)
            }
          }}>
          {steps?.map((label, index) => (
            <Step key={label} completed={completed?.[index]}>
              <StepButton onClick={() => handleStep(index)}>
                <StepLabel>{label}</StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
};

export default HorizontalStepper;
