import React, { FC } from 'react';
import { TextField } from '@mui/material';
import { isEmpty } from 'lodash';

import { AmountDisplayType } from './types';
import { getNumbersOnly } from '@utils/common';

const AmountDisplay: FC<AmountDisplayType> = ({
  amount,
  formatFunction,
  onClickAmountDisplay,
  description,
  customClass,
  label,
  wrapperClass,
  hasError,
  name,
  errors,
  placeholder,
  isDisabled = false
}) => {
  return (
    <div className={wrapperClass}>
      <TextField
        inputProps={{
          style: {
            height: '100%',
            backgroundColor: 'white'
          }
        }}
        disabled={isDisabled}
        className={`w-full ${customClass}`}
        InputProps={{ readOnly: true }}
        label={label}
        value={formatFunction(parseFloat(getNumbersOnly(amount)))}
        onClick={onClickAmountDisplay}
        error={hasError}
        placeholder={placeholder}
        InputLabelProps={{
          shrink: true
        }}
        helperText={
          !isEmpty(errors) && errors[name]
            ? (errors[name] as { message?: string })?.message
            : description
        }
      />
    </div>
  );
};

export default AmountDisplay;
