import React, {
  useState,
  MouseEvent,
  ChangeEvent,
  FC,
  useEffect,
  useRef
} from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { PaginationValues } from '@constants/common';

import { TableProps } from './types';
import './materialReactTable.module.css';

const CustomTable: FC<TableProps> = props => {
  const {
    tableHeaders,
    tableData,
    processTableData,
    isLoading = false,
    hideCheckbox = false,
    disableSort = false,
    disablePagination = false,
    elevation = 1,
    shouldWrapContent = false,
    page,
    handleLimitChange,
    rowsPerPage,
    handleOffsetChange,
    order,
    handleChangeOrder,
    orderBy,
    handleOrderBy,
    totalRows,
    handleRowClick = () => {},
    isRowClickable = false,
    onTableScroll,
    customColumnStyle,
    customHeaderColumnStyle,
    customContainerStyles,
    emptyState,
    enableStickyHeader
  } = props;

  const [selected, setSelected] = useState<readonly string[]>([]);
  const [maxHeight, setMaxHeight] = useState<number | undefined>();
  const rowHovered = useRef<number | undefined>();
  const tableXScrolled = useRef<boolean>(false);
  const prevScrollY = useRef<number>(0);

  const handleRequestSort = (_event: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    handleChangeOrder(isAsc ? 'desc' : 'asc');
    handleOrderBy(property as string);
    handleOffsetChange(0);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = tableData.map(item => item.id as string);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onScroll = (event: any) => {
    if (enableStickyHeader) {
      if (event.target.scrollLeft === 0) tableXScrolled.current = false;
      else tableXScrolled.current = true;

      const currentScrollY = event.target.scrollTop;
      if (currentScrollY > prevScrollY.current) {
        // Scrolling down
        window.scrollTo({
          top: window.innerHeight,
          left: 0,
          behavior: 'smooth'
        });
      }
      // Update the previous scroll position
      prevScrollY.current = currentScrollY;
    }
    onTableScroll?.(event);
  };

  useEffect(() => {
    if (enableStickyHeader) {
      const screenHeight = window.innerHeight;
      setMaxHeight(screenHeight - 135);
    }
  }, []);

  const renderTableHead = () => {
    const createSortHandler =
      (property: string) => (event: MouseEvent<unknown>) => {
        handleRequestSort(event, property);
      };

    const rowCount = tableData.length;
    const numSelected = selected.length;

    return (
      <TableHead>
        <TableRow>
          {!hideCheckbox && (
            <TableCell padding="checkbox" className={customHeaderColumnStyle}>
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={handleSelectAllClick}
              />
            </TableCell>
          )}
          {tableHeaders.map((headerCell, index) => (
            <TableCell
              className={`${
                enableStickyHeader && index === 0 ? 'sticky-cell-header' : ''
              } ${
                enableStickyHeader && index === 0 && tableXScrolled.current
                  ? 'shadow-right'
                  : ''
              } ${
                enableStickyHeader && rowHovered.current === index
                  ? 'sticky-row-header-hover-highlight'
                  : ''
              } ${customHeaderColumnStyle || ''}`}
              key={headerCell.id}
              align={'left'}
              padding={headerCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headerCell.id ? order : false}
              style={{ width: headerCell.width ? headerCell.width : 'auto' }}>
              {!disableSort && !headerCell.disableSort ? (
                <TableSortLabel
                  active={orderBy === headerCell.id}
                  direction={orderBy === headerCell.id ? order : 'asc'}
                  onClick={createSortHandler(headerCell.id)}>
                  <div
                    style={{
                      width: headerCell.labelWidth
                        ? headerCell.labelWidth
                        : 'auto',
                      whiteSpace: shouldWrapContent ? 'normal' : 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }}>
                    {headerCell.label}
                  </div>
                  {orderBy === headerCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <>{headerCell.label}</>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const handleChangePage = (newPage: number) => {
    handleOffsetChange(newPage * rowsPerPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    handleLimitChange(parseInt(event.target.value, 10));
    handleOffsetChange(0);
  };

  const handleClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;
  return (
    <div id="customTable">
      <Paper sx={{ width: '100%', mb: 2 }} elevation={elevation}>
        <>
          <TableContainer
            className={`${customContainerStyles}`}
            onScroll={onScroll}
            sx={
              enableStickyHeader
                ? {
                    maxHeight: maxHeight
                  }
                : {}
            }>
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{ tableLayout: 'fixed' }}>
              {renderTableHead()}
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={tableHeaders.length} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  tableData?.map((row, index) => {
                    const isItemSelected = isSelected(row.id as string);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={() => isRowClickable && handleRowClick(row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id as string}
                        selected={isItemSelected}
                        sx={{ cursor: `${isRowClickable && 'pointer'}` }}
                        onMouseEnter={() => {
                          if (enableStickyHeader) rowHovered.current = index;
                        }}
                        onMouseLeave={() => {
                          if (enableStickyHeader) {
                            rowHovered.current = undefined;
                          }
                        }}
                        className="font-normal">
                        {!hideCheckbox && (
                          <TableCell
                            padding="checkbox"
                            className={customColumnStyle}>
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId
                              }}
                              onClick={e => {
                                e.stopPropagation();
                                !hideCheckbox && handleClick(row.id as string);
                              }}
                            />
                          </TableCell>
                        )}
                        {tableHeaders.map((header, idx) => (
                          <TableCell
                            className={`${
                              enableStickyHeader && idx === 0
                                ? 'sticky-cell'
                                : ''
                            } ${
                              enableStickyHeader &&
                              idx === 0 &&
                              tableXScrolled.current
                                ? 'shadow-right'
                                : ''
                            } ${
                              enableStickyHeader && rowHovered.current === index
                                ? 'sticky-row-hover-highlight'
                                : ''
                            } ${customColumnStyle || ''}`}
                            key={
                              String(row[header.id]) +
                              String(index) +
                              String(idx)
                            }
                            align="left"
                            padding={header.disablePadding ? 'none' : 'normal'}
                            style={{
                              whiteSpace: shouldWrapContent
                                ? 'normal'
                                : 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden'
                            }}>
                            {processTableData(row, index)[header.id]}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })
                )}
                {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 69.5 * emptyRows
                    }}>
                    <TableCell colSpan={tableHeaders.length + 1} />
                  </TableRow>
                )} */}
                {emptyState && !tableData?.length && (
                  <TableRow>
                    <TableCell colSpan={tableHeaders.length}>
                      {emptyState}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {!disablePagination && (
            <TablePagination
              rowsPerPageOptions={PaginationValues ?? 0}
              component="div"
              count={totalRows ?? 0}
              rowsPerPage={rowsPerPage ?? 0}
              page={page ?? 0}
              onPageChange={(_, pageNum) => handleChangePage(pageNum)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      </Paper>
    </div>
  );
};

export default CustomTable;
