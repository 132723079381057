import React, { ChangeEvent, FC, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { Button, CircularProgress, Modal, Paper } from '@mui/material';
import { DualButton } from '@components';
import { colors } from '@constants/colors';
import { LavenderSearch } from '@assets/icons';
import { translate } from '@utils/locale';

import { SearchOptionItem, SelectOptionWithIconModalProps } from './types';

const SelectOptionWithIconModal: FC<SelectOptionWithIconModalProps> = ({
  title,
  primaryButtonLabel,
  secondaryButtonLabel,
  isModalOpen = true,
  itemsList,
  searchTerm,
  setSearchTerm,
  selectedItem,
  setSelectedItem,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onAddNewButtonClicked,
  isLoading
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.trimStart());
    setIsDropdownOpen(true);
    setSelectedItem(undefined);
  };

  const handleDropdownItemClick = (item: SearchOptionItem) => {
    setSelectedItem(item);
    setSearchTerm(item.name);
    setIsDropdownOpen(false);
  };

  useHotkeys('enter', () => onPrimaryButtonClick(), {
    enabled: isModalOpen && !!selectedItem
  });

  return (
    <Modal open={isModalOpen} onClose={onSecondaryButtonClick}>
      <div className="flex justify-center items-center h-full bg-black/60">
        <Paper
          style={{
            width: 444,
            height: 533
          }}>
          <div className="flex flex-col justify-between w-full h-full">
            <div className="p-5 text-xl font-medium leading-[120%] border-b border-b-softPeach">
              {title}
            </div>
            <div className="p-3 w-full h-full">
              <div className="relative w-full">
                <div
                  className="flex flex-wrap justify-between h-[48px] bg-white rounded-[4px] 
                border-[1px] border-borderGrey cursor-pointer ">
                  <input
                    className="self-start py-3 px-4 w-full h-full text-base font-normal leading-4 text-black rounded-[4px] outline-1 outline-primary cursor-text"
                    type="text"
                    placeholder={translate('company.companyListPage.search')}
                    value={searchTerm}
                    onChange={handleInputChange}
                    onFocus={() => setIsDropdownOpen(true)}
                    autoFocus
                  />
                </div>
                {isDropdownOpen && itemsList.length ? (
                  <ul
                    className={
                      'flex overflow-y-auto absolute z-[520] flex-col !pl-0 w-full max-h-[330px] bg-white rounded-xl'
                    }>
                    {isLoading ? (
                      <div className="flex justify-center mt-4 w-full">
                        <CircularProgress />
                      </div>
                    ) : (
                      itemsList?.map(item => (
                        <li
                          onClick={() => handleDropdownItemClick(item)}
                          key={item.id}
                          className={
                            'flex gap-3 items-center p-2 py-[7px] my-1 w-full h-[58px] text-base font-normal hover:bg-catskillWhite rounded-lg cursor-pointer  '
                          }>
                          {item.iconUrl ? (
                            <div className="p-[5px] rounded-lg border border-borderGrey">
                              <img
                                src={item.iconUrl}
                                alt={item.name}
                                className="max-w-[32px] max-h-8"
                              />
                            </div>
                          ) : (
                            <div className="flex justify-center items-center w-[42px] h-[42px] text-xl font-medium text-black rounded-lg border border-borderGrey">
                              {item.name[0]?.toUpperCase()}
                            </div>
                          )}
                          <div className="overflow-hidden h-full text-ellipsis">
                            <div>{item.name}</div>
                            <div className="text-sm text-gray-500">
                              {item.description}
                            </div>
                          </div>
                        </li>
                      ))
                    )}
                  </ul>
                ) : (
                  searchTerm &&
                  !selectedItem && (
                    <div className="flex justify-center items-center h-[330px]">
                      <div className="flex flex-col items-center w-[262px]">
                        <LavenderSearch />
                        <div className="mt-4 mb-2 w-full text-center">
                          {translate('searchOptionList.createItemNotFound', {
                            itemName: searchTerm
                          })}
                        </div>

                        <Button
                          variant="contained"
                          onClick={onAddNewButtonClicked}>
                          <div>
                            {translate('searchOptionList.createNewCompany')}
                          </div>
                        </Button>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="flex justify-end p-5 border-t border-t-softPeach">
              <DualButton
                primaryButtonLabel={primaryButtonLabel}
                secondaryButtonLabel={secondaryButtonLabel}
                secondaryButtonVariant="text"
                secondaryButtonCustomStyle={{
                  backgroundColor: colors.white,
                  color: colors.primaryBlue
                }}
                onChangePrimaryButton={onPrimaryButtonClick}
                onChangeSecondaryButton={onSecondaryButtonClick}
                isPrimaryDisabled={!selectedItem}
              />
            </div>
          </div>
        </Paper>
      </div>
    </Modal>
  );
};

export default SelectOptionWithIconModal;
