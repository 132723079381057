import React, { FC, useState } from 'react';
import {
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@mui/material';

import { PlusIcon } from '@assets/icons';
import { OptionsLOVType } from '@components/auto-complete/types';

import { AddButtonPropsType } from './types';

const AddButton: FC<AddButtonPropsType> = ({
  label,
  handleButtonClick,
  optionsList
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (optionsList && optionsList?.length > 0) {
      setAnchorEl(event.currentTarget);
      setOpen(curr => !curr);
    } else handleButtonClick();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(curr => !curr);
  };

  const handleOptionClick = (option: OptionsLOVType) => {
    handleButtonClick(option.id);
    handleClose();
  };

  return (
    <>
      <div className="group">
        <Button
          variant="outlined"
          startIcon={<PlusIcon />}
          onClick={handleClick}
          className="group-hover:text-white group-hover:bg-brightBlue">
          {label}
        </Button>
      </div>
      {optionsList && optionsList?.length > 0 && !!anchorEl && (
        <Popper
          open={open}
          anchorEl={anchorEl}
          role={undefined}
          transition
          disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {optionsList.map((option, index) => (
                      <div key={index} className="min-w-[140px]">
                        <MenuItem onClick={() => handleOptionClick(option)}>
                          {option.name}
                        </MenuItem>
                        {index === optionsList.length - 2 && <Divider />}
                      </div>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};

export default AddButton;
