import { createSlice } from '@reduxjs/toolkit';

import { ReducerStateType } from './types';

const initialState = {
  search: '',
  offset: 0,
  limit: 25,
  order: 'desc',
  sortBy: 'name',
  clearPage: true
} as ReducerStateType;

export const dealAnalyticsSlice = createSlice({
  name: 'dealAnalytics',
  initialState,
  reducers: {
    resetDealListingValues: state => ({
      ...state,
      offset: 0,
      limit: 25,
      order: 'desc',
      sortBy: 'name',
      search: ''
    }),
    updateDealAnalyticsValues: (state, { payload }) => ({
      ...state,
      ...payload
    })
  }
});

export const { resetDealListingValues, updateDealAnalyticsValues } =
  dealAnalyticsSlice.actions;

export default dealAnalyticsSlice.reducer;
