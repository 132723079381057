import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { IconButton, Modal } from '@mui/material';
import PictureAsPdfSharpIcon from '@mui/icons-material/PictureAsPdfSharp';
import { PlaceHolderImage } from '@assets/images';
import { CloseIcon, DeleteIcon } from '@assets/icons';
import { RegularExpressions } from '@constants/common';
import { translate } from '@utils/locale';

import { ImagePreviewCardProps } from './types';

const ImagePreviewCard: FC<ImagePreviewCardProps> = props => {
  const {
    imageUrl,
    name = '',
    fileName = '',
    fileSize = 0,
    isEdit,
    customCardStyle,
    handleDeleteClick = () => {},
    description
  } = props;

  const [isFetchingFailed, setIsFetchingFailed] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [fileType, setFileType] = useState('');

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleImageClick = () => {
    if (
      RegularExpressions.imageFileRegex.test(fileName) &&
      fileType !== 'application/pdf'
    ) {
      setIsModalOpen(true);
    } else {
      window.open(imageUrl, '_blank');
    }
  };

  const handleDownload = (fileUrl: string) => {
    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = '';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
    setIsModalOpen(false);
  };

  const onImageUploadError = (
    event: SyntheticEvent<HTMLImageElement, Event>
  ) => {
    if (!isFetchingFailed) {
      (event.target as HTMLImageElement).src = PlaceHolderImage;
      setIsFetchingFailed(true);
    }
  };

  useHotkeys('enter', () => handleDownload(imageUrl), {
    enabled: isModalOpen
  });

  useEffect(() => {
    fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        setFileType(blob.type);
      });
  }, [imageUrl]);

  return (
    <>
      <div
        className={`flex overflow-hidden flex-row h-[98px] rounded-lg border border-gray-200 cursor-pointer ${customCardStyle}`}>
        {fileType === 'application/pdf' ? (
          <div className="flex justify-center items-center w-[98px]">
            <PictureAsPdfSharpIcon
              fontSize="large"
              onClick={handleImageClick}
            />
          </div>
        ) : (
          <div className='flex justify-center bg-[url("/public/transparent.jpeg")]'>
            <img
              src={
                RegularExpressions.imageFileRegex.test(fileName)
                  ? imageUrl
                  : PlaceHolderImage
              }
              alt=""
              className="object-contain items-center self-center !w-[98px] max-w-[98px] cursor-pointer"
              onError={event => onImageUploadError(event)}
              referrerPolicy="no-referrer"
              onClick={handleImageClick}
            />
          </div>
        )}

        <div
          className="flex flex-row items-center ml-4 w-full"
          onClick={handleImageClick}>
          <div className="flex flex-col justify-center mx-4 w-[130px] 2xl:w-[200px]">
            <div className="text-base font-normal truncate">{fileName}</div>
            <div className="pt-1 text-sm font-normal text-black/60">
              {fileSize ? `${fileSize}Mb` : ''}
            </div>
          </div>
          {isEdit && (
            <div
              className="flex justify-end mr-9 w-full"
              onClick={event => event.preventDefault()}>
              <DeleteIcon
                onClick={event => {
                  event.stopPropagation();
                  handleDeleteClick(name);
                }}
                className="cursor-pointer"
              />
            </div>
          )}
        </div>

        <Modal
          open={isModalOpen}
          onClose={closeModal}
          aria-labelledby="image-preview">
          <div className="flex justify-center items-center h-full bg-black/60">
            <div
              className="flex relative flex-col justify-center p-4 min-w-[360px] max-w-3xl max-h-fit
             bg-white rounded-lg shadow-lg">
              <IconButton
                edge="end"
                color="inherit"
                onClick={closeModal}
                aria-label="close"
                sx={{
                  position: 'absolute',
                  top: '2%',
                  right: '4%'
                }}>
                <CloseIcon />
              </IconButton>
              <div className="flex justify-center items-center p-4 m-4 rounded-md border border-gray-300">
                <img src={imageUrl} alt="" className="max-h-[400px]" />
              </div>
              <div className="flex justify-end pt-4">
                <button
                  className="py-2 px-4 text-white bg-primary rounded-md"
                  onClick={() => handleDownload(imageUrl)}>
                  {translate('download')}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      {description && (
        // eslint-disable-next-line tailwindcss/no-custom-classname
        <p
          className={`mx-[14px] mt-[3px] text-xs leading-5 text-black ${
            isEdit ? 'text-opacity-60' : 'text-opacity-40'
          }`}>
          {description}
        </p>
      )}
    </>
  );
};

export default ImagePreviewCard;
