import React, { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';

const ErrorFallback: FC<FallbackProps> = ({ resetErrorBoundary }) => {
  return (
    <div
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className="flex flex-col justify-center items-center 
    w-full h-screen bg-right-top bg-no-repeat">
      <div className="items-center text-base font-medium text-black">
        Something went wrong
      </div>
      <button
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className="flex justify-center items-center py-2 px-4 mt-5 min-w-[200px] text-sm 
        font-medium leading-5 text-black rounded-3xl border-[1px] border-black lg:min-w-[0px]"
        type="button"
        onClick={resetErrorBoundary}>
        Try Again
      </button>
    </div>
  );
};

export default ErrorFallback;
