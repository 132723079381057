import React from 'react';
import { GreenTick, RedCloseIcon, WarningTriangle } from '@assets/icons';

export enum ToastTypes {
  SUCCESS = 'Success',
  ERROR = 'Error',
  WARNING = 'Warn'
}

export const ToastObject = {
  [ToastTypes.ERROR]: {
    bg: 'bg-[#FCEFEA]',
    border: 'border-[#E7D3CE]',
    Icon: <RedCloseIcon height={36} width={36} />,
    customStyles: '!py-5'
  },
  [ToastTypes.SUCCESS]: {
    bg: 'bg-[#F1F9F4]',
    border: 'border-[#D5E8D9]',
    Icon: <GreenTick height={36} width={36} />,
    customStyles: ''
  },
  [ToastTypes.WARNING]: {
    bg: 'bg-[#FEF7EA]',
    border: 'border-[#F0DEBD]',
    Icon: <WarningTriangle height={36} width={36} />,
    customStyles: ''
  }
};
