/* eslint-disable tailwindcss/no-custom-classname */
import React, { FC, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { translate } from '@utils/locale';
import { logout } from '@utils/auth';
import { colors } from '@constants/colors';
import { useSelector } from 'react-redux';
import { RootState } from '@store/reducers';
import RoutesPath from '@routes/RoutesPath';

import { NavBarProps } from './types';

const NavBar: FC<NavBarProps> = props => {
  const { navBarItems, title, userProfile } = props;

  const [selectedTab, setSelectedTab] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const unverifiedDocsCount = useSelector(
    (state: RootState) =>
      state.rootReducer.unVerifiedDocsCount.unVerifiedDocsCount
  );
  const screeningUsersCount = useSelector(
    (state: RootState) => state.rootReducer.unVerifiedDocsCount.pendingScreening
  );

  useEffect(() => {
    const currentPath = location.pathname;
    const firstPathSegment = currentPath.split('/')[1];
    setSelectedTab(`/${firstPathSegment}`);
  }, [location.pathname]);

  const handleNavBarItemClick = (
    path: string,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (event.button === 0) {
      setSelectedTab(path);
      navigate(path);
    }
  };

  const handleProfileIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const getInitials = (name: string) => {
    const names = name.split(' ');
    return names
      .map(n => n.charAt(0))
      .join('')
      .toUpperCase();
  };

  const handleLogoutClick = () => {
    logout();
  };

  return (
    <div
      // eslint-disable-next-line tailwindcss/classnames-order
      className="flex fixed bottom-0 flex-col justify-between w-60 h-[calc(100%-4rem)]
       border-r-2 border-gray-100 bg-balticSea">
      <div className="flex flex-col">
        {title}
        {navBarItems.map(navBarItem => {
          const { icon: NavbarIcon } = navBarItem;
          return (
            <Link
              key={navBarItem.path}
              to={navBarItem.path}
              className={`flex overflow-hidden relative flex-row items-center py-2 px-4  
              h-12 cursor-pointer hover:bg-midGrey ${
                selectedTab === navBarItem.path
                  ? 'bg-black text-primaryGreen before:absolute before:left-0 before:top-0 before:bottom-0 before:w-1 before:bg-primaryGreen hover:!bg-black'
                  : ''
              }`}
              onClick={e => {
                e.preventDefault();
                handleNavBarItemClick(navBarItem.path, e);
              }}>
              {NavbarIcon && <NavbarIcon />}
              <span
                className={`overflow-hidden ml-3 text-base ${
                  selectedTab === navBarItem.path
                    ? ' text-primaryGreen !font-medium'
                    : 'text-white font-normal'
                } whitespace-nowrap
                 hover:scale-100`}>
                {navBarItem.cta}
              </span>
              {navBarItem.path === RoutesPath.VERIFY_USER && (
                // eslint-disable-next-line tailwindcss/classnames-order
                <div className="flex justify-center items-center ml-3  w-6 h-6 text-[10px] font-normal text-black rounded-full bg-primaryGreen">
                  {unverifiedDocsCount}
                </div>
              )}
              {navBarItem.path === RoutesPath.SCREENING && (
                // eslint-disable-next-line tailwindcss/classnames-order
                <div className="flex justify-center items-center ml-3  w-6 h-6 text-[10px] font-normal text-black rounded-full bg-primaryGreen">
                  {screeningUsersCount}
                </div>
              )}
            </Link>
          );
        })}
      </div>
      <div className="py-2 px-4 bg-pearlGrey">
        <div className="flex gap-2 items-center">
          <IconButton
            edge="end"
            color="inherit"
            aria-controls="profile-menu"
            aria-haspopup="true">
            <div
              style={{
                width: 40,
                height: 40,
                backgroundColor: 'black',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 18,
                fontWeight: 'bold',
                color: 'white'
              }}>
              {getInitials(userProfile?.first_name || '')}
            </div>
          </IconButton>
          <div className="flex flex-row justify-between items-center w-full">
            <div>
              <div className="text-sm font-normal leading-5 text-left text-white">
                {userProfile?.first_name || ''}
              </div>
              <div className="text-sm font-normal text-left text-chaliceGrey">
                {userProfile?.title || ''}
              </div>
            </div>
            <Button
              onClick={handleProfileIconClick}
              className="cursor-pointer"
              sx={{
                '&:hover': {
                  backgroundColor: '#8C8C8C'
                }
              }}>
              <MoreHorizIcon
                sx={{ color: colors.primaryGreen }}
                fontSize="large"
              />
            </Button>
          </div>
        </div>
        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          MenuListProps={{ disablePadding: true }}
          slotProps={{
            paper: {
              style: {
                backgroundColor: '#444444',
                color: 'white',
                width: '176px'
              }
            }
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}>
          <MenuItem
            sx={{
              '&:hover': {
                backgroundColor: '#8C8C8C'
              }
            }}
            style={{ height: '52px ', padding: '0px 20px' }}
            onClick={handleLogoutClick}>
            {translate('logout')}
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default NavBar;
