import React, { FC } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Modal, Paper } from '@mui/material';

import DualButton from '@components/dual-button/DualButton';

import { CustomModalPropsType } from './types';

const CustomModal: FC<CustomModalPropsType> = ({
  header,
  body,
  primaryButtonLabel,
  secondaryButtonLabel,
  isDeleteButton,
  primaryButtonVariant,
  secondaryButtonVariant,
  onChangePrimaryButton,
  onChangeSecondaryButton,
  customStyle = 'justify-center self-center',
  primaryButtonCustomStyle = {},
  secondaryButtonCustomStyle = {},
  isModalOpen,
  customButtonContainerStyles,
  width,
  customButton,
  customModalStyle,
  paddingLeft,
  paddingRight,
  primaryEndIcon,
  primaryStartIcon,
  secondaryEndIcon,
  secondaryStartIcon,
  buttongroupStyles,
  isPrimaryButtonDisabled = false,
  isPrimaryButtonLoading = false
}) => {
  useHotkeys(
    'enter',
    () => {
      onChangePrimaryButton?.();
    },
    {
      enabled: isModalOpen && !isPrimaryButtonDisabled
    }
  );

  return (
    <Modal open={isModalOpen} onClose={onChangeSecondaryButton}>
      <div className="flex justify-center items-center h-full bg-black/60">
        <Paper
          style={{
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: paddingLeft ?? 24,
            paddingRight: paddingRight ?? 24,
            minWidth: 444,
            width: width,
            maxHeight: 'calc(100vh - 50px)',
            overflow: 'auto'
          }}>
          <div className={`flex flex-col ${customModalStyle}`}>
            <div
              className={`${customStyle} mb-4 text-xl font-medium leading-8 text-center`}>
              {header}
            </div>
            {body}
            <div
              className={`flex ${
                customButtonContainerStyles
                  ? customButtonContainerStyles
                  : 'justify-end'
              } `}>
              <div className="flex justify-center items-center">
                {customButton}
              </div>
              <div className={`${buttongroupStyles} self-end`}>
                <DualButton
                  deleteButton={isDeleteButton}
                  lowerCaseLabel
                  primaryButtonLabel={primaryButtonLabel}
                  secondaryButtonLabel={secondaryButtonLabel}
                  secondaryButtonVariant={secondaryButtonVariant}
                  primaryButtonVariant={primaryButtonVariant}
                  onChangePrimaryButton={onChangePrimaryButton}
                  onChangeSecondaryButton={onChangeSecondaryButton}
                  primaryButtonCustomStyle={primaryButtonCustomStyle}
                  secondaryButtonCustomStyle={secondaryButtonCustomStyle}
                  primaryEndIcon={primaryEndIcon}
                  primaryStartIcon={primaryStartIcon}
                  secondaryEndIcon={secondaryEndIcon}
                  secondaryStartIcon={secondaryStartIcon}
                  isPrimaryDisabled={isPrimaryButtonDisabled}
                  isPrimaryButtonLoading={isPrimaryButtonLoading}
                />
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </Modal>
  );
};

export default CustomModal;
