import React, { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { MenuItem, InputLabel, FormControl, Select } from '@mui/material';
import { CloseIcon } from '@assets/icons';
import { colors } from '@constants/colors';

import { RhfDropdownProps } from './types';

const dropdownCustomStyles = {
  height: '52px',
  '& label.Mui-focused': {
    color: colors.primaryBlue
  },
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000ab'
  },
  '.MuiSelect-outlined': {
    backgroundColor: 'white'
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: colors.gray88,
    borderRadius: '5px',
    borderWidth: '1px'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.primaryBlue,
    borderRadius: '5px',
    borderWidth: '1px'
  },
  '.MuiSelect-icon': {
    right: '5px !important'
  }
};

const RhfDropdown: FC<RhfDropdownProps> = props => {
  const {
    placeholder,
    name,
    control,
    dropdownLOV,
    errors,
    isDisabled = false,
    selectedItem = '',
    onChangeHandler = () => null,
    wrapperClass = '',
    customRenderValue,
    getCustomMenuItem,
    customMenuProps,
    handleMenuItemClick,
    descriptor,
    disableClose,
    description,
    onFocus,
    autoFocusField = false,
    fieldRef
  } = props;

  const { setValue } = useFormContext();

  const [selectedOption, setSelectedOption] = useState(selectedItem);

  useEffect(() => {
    setSelectedOption(selectedItem);
  }, [selectedItem]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <div className={`w-full ${wrapperClass}`}>
          <FormControl
            fullWidth
            error={Boolean(
              !isEmpty(errors) &&
                (errors[name] as { message?: string })?.message
            )}>
            <InputLabel id="selectDropdownLabel">{placeholder}</InputLabel>
            <Select
              sx={dropdownCustomStyles}
              labelId="selectDropdownLabel"
              label={placeholder}
              placeholder={descriptor}
              value={selectedOption}
              disabled={isDisabled}
              error={Boolean(
                !isEmpty(errors) &&
                  (errors[name] as { message?: string })?.message
              )}
              onChange={event => {
                setSelectedOption(event.target.value);
                onChange(event);
                onChangeHandler(event.target.value);
              }}
              onFocus={onFocus}
              MenuProps={customMenuProps}
              endAdornment={
                !disableClose &&
                selectedOption &&
                !isDisabled && (
                  <CloseIcon
                    onClick={event => {
                      if (isDisabled) return;
                      event.stopPropagation();
                      setValue(name, '', { shouldDirty: true });
                      setSelectedOption('');
                      onChangeHandler('');
                    }}
                    className="absolute top-[calc(50%-6px)] right-8 w-2.5 h-3 cursor-pointer"
                  />
                )
              }
              renderValue={customRenderValue}
              autoFocus={autoFocusField}
              inputRef={fieldRef}>
              {dropdownLOV?.map(option => (
                <MenuItem
                  data-testid={option.id}
                  value={option.id}
                  key={option.id}
                  onClick={() =>
                    handleMenuItemClick
                      ? handleMenuItemClick(option.id)
                      : () => {}
                  }
                  disabled={option.isDisabled}>
                  {getCustomMenuItem ? getCustomMenuItem(option) : option.name}
                </MenuItem>
              ))}
            </Select>
            {!isEmpty(errors) &&
            (errors[name] as { message?: string })?.message ? (
              <p className="mx-[14px] mt-[3px] text-xs leading-5 text-red">
                {(errors[name] as { message?: string })?.message}
              </p>
            ) : (
              description && (
                // eslint-disable-next-line tailwindcss/no-custom-classname
                <p
                  className={`mx-[14px] mt-[3px] text-xs leading-5 text-black ${
                    isDisabled ? 'text-opacity-40' : 'text-opacity-60'
                  }`}>
                  {description}
                </p>
              )
            )}
          </FormControl>
        </div>
      )}
    />
  );
};

export default RhfDropdown;
